//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWithLinks from '../components/templates/pageWithLinks'
import PageBackground from '../components/free/pageBackground'

const Homemaker: FunctionComponent<PageWithLinksTypes> = ({ data }) => {
  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Homemaker</title>
          <link rel="canonical" href="https://ooa.csi.edu/homemaker/" />
          <meta
            name="description"
            content="Homemaker Service is a program available to seniors aged 60 years and older who need assistance in order to remain in their own homes, thus maintaining their independence and dignity."
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        />
      </Layout>

      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default Homemaker

export const homemaker = graphql`
  query homemaker {
    markdownRemark(frontmatter: { template_key: { eq: "homemaker-page" } }) {
      html
      frontmatter {
        title
        links {
          link
          link_name
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        team_pictures {
          name
          title
          img {
            childImageSharp {
              fluid(maxWidth: 1700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
